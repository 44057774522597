import React, { useState, useEffect } from "react"
import Slider from "react-slick"
import { Button } from "antd"
import { useTranslation } from "react-i18next"
import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"

import { Multimedia } from "../../Multimedia/Multimedia"
import { getOS } from "../../../@common/utils/getOS"

const playbackRate = {
  understand_operation: 0.9,
  available: 0.7,
  allies: 1,
}

export const SectionMulticarrier = ({ items, handlerContact }) => {
  const { t } = useTranslation()

  const [currentIndex, setCurrentIndex] = useState(0)
  const [tabs, setTabs] = useState()
  const [os, setOS] = useState()

  useEffect(() => {
    const setItems = items.map(item => {
      if (playbackRate[item.key]) item.playbackRate = playbackRate[item.key]
      return item
    })

    setTabs(setItems)

    setOS(getOS())
  }, [])

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 250,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    beforeChange: (current, next) => setCurrentIndex(next),
    responsive: [
      {
        breakpoint: 804,
        settings: {
          vertical: false,
          verticalSwiping: false,
        }
      },
    ]
  }

  return (
    <section className={`SectionMulticarrier SectionMulticarrier--background-${os}`}>
      <div className="SectionMulticarrier--container">
        <div className="slides">
          <Slider {...sliderSettings}>
            {items?.map((item, index) => (
              <div key={index} className="content">
                <div className="content__rigth">
                  <Multimedia
                    interval={5000}
                    playing={currentIndex === index}
                    className={"img-generators"}
                    src={item.image}
                    // playbackRate={item.playbackRate}
                  />
                </div>
                <div className="content__left">
                  <h3 className="content__left--subtitle">Multicarrier</h3>
                  <h1 className="content__left--title">{item.title}</h1>
                  <p className="content__left--text">
                    {item.description?.description}
                  </p>
                </div>
              </div>
            ))}
          </Slider>
          <div className="cont-btn">
            <Button className="btn btn--demo" onClick={handlerContact}>
              {t("button.contact")}
            </Button>
            {/*<Button className="btn btn--primary">
              {t("button.see_benefits")}
            </Button>*/}
          </div>
        </div>
        {tabs && (
          <div className="cont">
            <AliceCarousel
              slideToIndex={currentIndex}
              duration={250}
              mouseTrackingEnabled={false}
            >
              {tabs.map((item, index) => (
                <Multimedia
                  key={index}
                  interval={5000}
                  playing={currentIndex === index}
                  className="img-generators"
                  src={item.image}
                  playbackRate={item.playbackRate}
                />
              ))}
            </AliceCarousel>
          </div>
        )}
      </div>
    </section>
  )
}
