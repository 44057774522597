export const HUBSPOT = {
  FORMS_NEW_LEAD_NATURAL: { 
    id: '48de03e2-4a5b-4712-86d0-f6ebb4b3efa2',
    keys: [
      'type_person',
      'prefix_number',
      'prefix_country',
      'phone',
      'name',
      'rol',
      'type_document',
      'document',
      'email',
      'city',
      'available',
      'type_license',
      'how_did_find_out',
      'certification',
      'plate',
    ]
  },
  FORMS_NEW_LEAD_JURIDICAL: { 
    id: 'd245e166-0114-408e-ad55-a591ebdea1c2',
    keys: [
      'type_person',
      'prefix_number',
      'prefix_country',
      'phone',
      'name',
      'document',
      'email',
      'city',
      'how_did_find_out',
      'contact_name',
      'logistics_services'
    ]
  },
  FORM_GENERATOR: { 
    id: '8a63f007-1de2-42e3-806f-8fd5f1aac693',
    keys: [
      'email',
      'firstname',
      'country',
      'phone',
      'company',
      'industry',
      'empleados_empresa',
      'cual_es_tu_inversion_mensual_transporte_',
      'numero_de_empleados_en_tu_empresa',
      'servicio_de_tu_interes_'
    ]
  },
  FORM_OPERATOR: {
    id: '2da6626c-cc68-4f9a-829d-7854f4329767',
    keys: [
      
    ]
  },
  FORM_SUSCRIBE: {
    id: 'f9e68773-9a7d-4f04-8b35-f57463d30438',
    keys: ['email']
  }
}