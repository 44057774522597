import React from "react"
import { Button } from "antd"
import Slider from "react-slick"
import { useTranslation } from "react-i18next"

export const SectionIndustries = ({ 
  title, 
  description, 
  link, 
  items,
  handlerContact
}) => {
  const { t } = useTranslation()

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 2,
    // autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: false
        }
      }
    ]
  }

  return (
    <div className="SectionIndustries">
      <div className="SectionIndustries__content">
        <div className="SectionIndustries__content--left">
          <a
            target="_blank"
            href={link?.link.link}
            rel="noreferrer"
            className="subtitle"
          >
            {link?.label}
          </a>
          <h1 className="title">{title}</h1>
          <p className="text">{description}</p>
          <div className="cont-btn">
            <Button className="btn btn--primary" onClick={handlerContact}>
              {t("button.contact")}
            </Button>
            <Button className="btn btn--demo">
              Ver demo
            </Button>
          </div>
        </div>
        <div className="SectionIndustries__content--rigth cont-des">
          {items?.map((item, i) => (
            <Button className="btn-gray" key={i}>
              {item.label}
            </Button>
          ))}
        </div>
        <div className="cont-res">
          <Slider {...settings}>
            {items?.map((item, i) => (
              <Button className="btn-gray" key={i}>
                {item.label}
              </Button>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}
