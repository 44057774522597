import React from "react"
import Slider from "react-slick"
import { Multimedia } from "../../Multimedia/Multimedia"

export const SectionTechnology = ({ items }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <section className="SectionTechnology">
      <div className="SectionTechnology--container cont-des">
        {items?.map((item, index) => (
          <div key={index} className="SectionTechnology--container__card">
            <Multimedia
              id={item.key}
              src={item.image} 
              hover
              className={"video--gen"}
            />
            <h1 className="title">{item.name}</h1>
            <p className="description">{item.description?.description}</p>
            <a
              target="_blank"
              rel="noreferrer"
              className="see-more"
              href={item.link?.link.link}
            >
              {item.link?.label}
            </a>
          </div>
        ))}
      </div>

      <div className="SectionTechnology--container cont-res">
        <div className="caroucel--res">
          <Slider {...settings}>
            {items?.map((item, index) => (
              <div key={index} className="SectionTechnology--container__card">
                <Multimedia
                  id={item.key}
                  src={item.image} 
                  hover
                  className={"video--gen"}
                />
                <h1 className="title">{item.name}</h1>
                <p className="description">{item.description?.description}</p>
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="see-more"
                  href={item.link?.link.link}
                >
                  {item.link?.label}
                </a>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  )
}
