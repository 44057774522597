import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Button } from "antd"
import { Link } from "gatsby"
import { Multimedia } from "../../Multimedia/Multimedia"
import { navigatePage } from "../../../@common/utils/navigate"

const playbackRate = {
  last_mile: 0.4,
  first_mile: 1,
  long_haul: 1,
}

export const SectionOperationsSolution = ({ title, description, items }) => {
  const { t } = useTranslation()

  const [tabs, setTabs] = useState()
  const [itemSelect, setItemSelect] = useState()

  useEffect(() => {
    const setItems = items.map(item => {
      if (playbackRate[item.key]) item.playbackRate = playbackRate[item.key]
      return item
    })

    setTabs(setItems)

    if(typeof window !== "undefined"){
      const querys = window.location.search
      const itemOpen = items.find(item => querys.includes(item.key))
      setItemSelect(itemOpen)
    }
  }, [])

  return (
    <section className="SectionOperationsSolution">
      <div className="SectionOperationsSolution__container">
        <div
          className={`section1 animate__animated ${
            itemSelect && "animate__fadeOut desisable"
          }`}
        >
          <h1 className="title">{title}</h1>
          <p className="sub-title">{description}</p>
          <div className="content-btn" data-aos="fade-up">
            {tabs?.map((item, i) => (
              <div key={i}>
                <Button
                  className={`
                    button ${i === 0 && "button--first"}
                    ${i + 1 === tabs.length && "button--last"} 
                  `}
                  onClick={() => setItemSelect(item)}
                >
                  {item.title}
                </Button>
                <Button onClick={() => setItemSelect(item)} className="more">
                  <span>+</span>
                </Button>
              </div>
            ))}
          </div>
        </div>
        {itemSelect && (
          <div className="section2">
            <div className="box box--left animate__animated animate__fadeInLeft">
              <Multimedia
                className="img"
                playbackRate={itemSelect.playbackRate}
                src={itemSelect.image}
                alt={itemSelect.key}
              />
            </div>
            <div className="box box--right animate__animated animate__fadeInRight">
              <div className="btns">
                {tabs?.map((item, i) => (
                  <div key={i}>
                    <Button
                      className={`
                        button ${i === 0 && "button--first"}
                        ${i + 1 === tabs.length && "button--last"} 
                        ${itemSelect.key === item.key && "button--active"}
                      `}
                      onClick={() => setItemSelect(item)}
                    >
                      {item.title}
                    </Button>
                  </div>
                ))}
              </div>

              <div>
                <div className="content-card animate__animated animate__fadeIn">
                  <h1 className="title">{itemSelect.title}</h1>
                  <p className="sub-title">
                    {itemSelect.description?.description}
                  </p>
                </div>
              </div>

              <div className="cont-btn">
                <Button className="btn btn--primary" onClick={() => navigatePage('/revolutionizes-deliveries')}>
                  {t("button.revolutionize_deliveries")}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}
