import React from "react"
import { useInView } from "react-intersection-observer"

export const SectionPlanning = ({ title, comment, items }) => {
  const [sectionTitle, inSectionTitle] = useInView({ threshold: 0 })

  return (
    <div className="SectionPlanning">
      <div className="SectionPlanning--container">
        <h1 className="title">{title}</h1>
        <div
          className={`line--blue svgRight svg--des ${inSectionTitle && "animated-line"
            }`}
        >
          <svg
            width="936"
            height="356"
            viewBox="0 0 936 356"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.86182 88.6459L53.8781 240.316C54.1296 241.064 54.7971 241.594 55.5823 241.669L197.658 255.331C198.393 255.402 199.029 255.871 199.313 256.552L225.971 320.533C226.359 321.465 227.382 321.961 228.355 321.69L439.44 262.75C440.211 262.535 440.779 261.881 440.885 261.088L454.407 159.675C454.527 158.774 455.239 158.067 456.141 157.955L600.13 139.945C600.772 139.865 601.414 140.1 601.852 140.578L796.151 352.478C796.712 353.089 797.592 353.291 798.363 352.985L875.204 322.48C875.71 322.279 876.112 321.881 876.317 321.377L932.746 183.072C932.945 182.585 932.943 182.04 932.743 181.554L858.214 1.01538"
              stroke="#57FFFE"
              strokeWidth="5"
            />
          </svg>
        </div>

        <div className="linea-recta">
          <svg className="linea" width="4" height="400" viewBox="0 0 4 400" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 0.949219V399.949" stroke="#57FFFE" strokeWidth="3"/>
          </svg>
        </div>

        <div
          className={`line--blue svgRight svg--res ${inSectionTitle && "animated-line"
            }`}
        >
          <svg
            width="40"
            height="413"
            viewBox="0 0 40 413"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23.7479 0.388672L18.616 36.0557C18.5902 36.2352 18.5401 36.4102 18.4671 36.5761L9.16822 57.7175C9.08702 57.9021 9.03421 58.0979 9.01158 58.2983L3.00016 111.531C2.97134 111.787 2.99194 112.045 3.06082 112.292L23.2989 184.975C23.5588 185.908 23.1125 186.894 22.2398 187.314L6.63132 194.828C5.81823 195.22 5.36799 196.107 5.53231 196.994L8.6395 213.773C8.6892 214.042 8.68344 214.317 8.62257 214.583L3.02688 239.045C2.94973 239.382 2.96138 239.733 3.06069 240.065L23.6637 308.817C23.7196 309.003 23.7479 309.196 23.7479 309.391L23.7479 338.57C23.7479 338.869 23.8146 339.163 23.943 339.432L37.2941 367.403C37.6255 368.098 37.5292 368.921 37.0462 369.52L15.4587 396.299C14.9015 396.991 14.8667 397.967 15.3734 398.696L23.7479 410.748"
              stroke="#57FFFE"
              strokeWidth="5"
            />
          </svg>
        </div>

        <div className="SectionPlanning--container--cards" ref={sectionTitle}>
          {items?.map((item, index) => (
            <div key={index} className={`contents contents--${index}`}>
              <div>
                <div className="content1">
                  <div className="circle">
                    <div className="ball" />
                  </div>
                </div>
                <div className="down" />
              </div>
              <div className="content2">
                <h2 className="content2__title">{item.title}</h2>
                <div className="cont-hidden">
                  <div className="content2__line" />
                  <p>{item.description?.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="cont-card">
          {comment &&
            <div className="card">
                <img src={comment.photo} alt="avatar" />
                <div className="content">
                  <div>
                    <h2>{comment.nombreCliente}</h2>
                    <p>{comment.comment_title}</p>
                  </div>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={comment.redirect.link.link}
                  >
                    {comment.redirect.label}
                  </a>
                </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}
