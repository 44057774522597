import React, { useState } from "react"

export const CardGeneralComments = ({ title, text, footer, avatar }) => {
  const [active] = useState(false)

  return (
    <div className="CardGeneralComments">
      <div className={`card card${active && "--active"}`}>
        <img className="card__avatar" src={avatar} alt="avatar-profile" />
        <div className="card__content">
          <h2 className="card__content--title">{title}</h2>
          <p className="card__content--text">{text}</p>
          <p className="card__content--name">{footer}</p>
        </div>
      </div>
    </div>
  )
}
