import React, { useState, useMemo } from "react"
import Slider from "react-slick"
import { useTranslation } from "react-i18next"
import { RightOutlined, LeftOutlined } from "@ant-design/icons"
import arrow_left from "../../../assets/images/arrow-left.png";
import arrow_right from "../../../assets/images/arrow-right.png";

export const SectionCaroucelBrands = ({ items }) => {
  const { t } = useTranslation()

  const [itemsCustom, setItemsCustom] = useState()
  const [activeSlide, setActiveSlide] = useState(0)

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: (current, next) => setActiveSlide(next),
  }

  useMemo(() => {
    const parserItems = items.map(item => {
      if (typeof item.metada[0]?.internal.content === "string")
        item.backgroundColor = JSON.parse(
          item.metada[0]?.internal.content
        ).color
      return item
    })

    setItemsCustom(parserItems)
  }, [])

  return (
    <section className="SectionCaroucelBrands">
      <div className="SectionCaroucelBrands--container" data-aos="fade-up">
        <div className="caroucel-gene">
          <Slider {...settings}>
            {itemsCustom?.map((item, index) => (
              <div
                key={index}
                className="cont"
                style={{ background: item.backgroundColor }}
              >
                <div className="left">
                  <div
                    className="cont--img"
                    style={{ background: item.backgroundColor }}
                  >
                    <img className="image" src={item.image} alt="imagen" />
                  </div>
                  <div className="card-body">
                    <div className="card">
                      <div className="card-cont animate__animated animate__fadeIn">
                        <p className="text">{item.description?.description}</p>
                        <a className="a" href={item.link?.link.link}>
                          {t("button.see_case")}
                        </a>
                      </div>
                      <div className="line" />
                      {item.author && (
                        <div className="profile animate__animated animate__fadeIn">
                          <img src={item.author.photo} alt="imagen avatar" />
                          <div>
                            <h1>{`${item.author.name} ${item.author.lastname}`}</h1>
                            <p className="case">{item.author.profile}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="right">
                  <div className="content-card">
                    <div className="card">
                      <div className="card-cont animate__animated animate__fadeIn">
                        <p className="text">{item.description?.description}</p>
                        <a className="a" href={item.link?.link.link}>
                          {t("button.see_case")}
                        </a>
                      </div>
                      <div className="line" />
                      {item.author && (
                        <div className="profile animate__animated animate__fadeIn">
                          <img src={item.author.photo} alt="imagen avatar" />
                          <div>
                            <h1>{`${item.author.name} ${item.author.lastname}`}</h1>
                            <p className="case">{item.author.profile}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>

      </div>
    </section>
  )
}

const SampleNextArrow = props => {
  const { onClick } = props
  return (
    <div
      className="button-current-section-brands brands-next"
      onClick={onClick}
    >
      <img src={arrow_right} alt="arrow_left" />
    </div>
  )
}
const SamplePrevArrow = props => {
  const { onClick } = props
  return (
    <div
      className="button-current-section-brands brands-prev"
      onClick={onClick}
    >
      <img src={arrow_left} alt="arrow_left" />
    </div>
  )
}
