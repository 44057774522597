import React from "react"
import { useInView } from "react-intersection-observer"
import { useTranslation } from "react-i18next"

import { CardGeneralComments } from "../../Cards/CardGeneralComments/CardGeneralComments"

export const SectionComunity = ({
  title,
  description,
  image,
  link,
  comment,
}) => {
  const { t } = useTranslation()

  const [sectionTitle, inSectionTitle] = useInView({ threshold: 0 })

  return (
    <section className="SectionComunity">
      <div className="SectionComunity--container">
        <div className="left">
          <h3 className="subtitle">{t("general.community")}</h3>
          <h1 className="title">{title}</h1>
          <p className="description">{description}</p>
          <a target="__blank" href={link?.link.link} className="see-more">
            {link.label}
          </a>
          <div className="card-content">
            <CardGeneralComments
              title={comment.comment_title}
              text={comment.comment.comment}
              footer={comment.nombreCliente}
              avatar={comment.photo}
            />
          </div>
        </div>
        <div className="rigth" ref={sectionTitle}>
          <img className="we-lifters" src={image} alt="weLifters" />
          <div className="background--dark" />
          <div className="rigth__svg-prof">
            <div className={`svgRight ${inSectionTitle && "animated-line"}`}>
              <svg className="svg" width="105px" height="61px">
                <path
                  fill="transparent"
                  d="M175.991 1.48828H45.9907L1.49072 66.9883"
                  stroke="#FF7A57"
                  strokeWidth="1"
                  className="path-right"
                />
              </svg>
              <h3 className="css-typing__title css-typing__title-r">
                {t("general.professionalism")}
              </h3>
            </div>
          </div>

          <div className="rigth__svg-cum">
            <div className={`svgRight ${inSectionTitle && "animated-line"}`}>
              <svg className="svg" width="135px" height="61px">
                <path
                  fill="transparent"
                  d="M175.991 1.48828H45.9907L1.49072 66.9883"
                  stroke="#FF7A57"
                  strokeWidth="2"
                  className="path-right"
                />
              </svg>
              <h3 className="css-typing__title css-typing__title-r title-r-two">
                {t("general.compliance")}
              </h3>
            </div>
          </div>

          <div className="rigth__svg-hea">
            <div className={`svgRight ${inSectionTitle && "animated-line"}`}>
              <svg width="277px" height="100px">
                <path
                  fill="transparent"
                  d="M2321155.991 10.515317H45.9907L1.49072 37.9883"
                  stroke="#FF7A57"
                  strokeWidth="2"
                  className="path-right"
                />
              </svg>
              <h3 className="css-typing__title css-typing__title-r title-r-three">
                {t("general.hearth")}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
