import React, { useState } from "react"
import PropTypes from "prop-types"
import Slider from "react-slick"
import { Button } from "antd"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import play from "../../assets/images/play.png"
import Layout from "../../components/Layout/LayoutPublic/LayoutPublic"
import { Banner } from "../../components/BannerMaps/BannerMaps"
import { SectionTechnology } from "../../components/PageGenerators/SectionTechnology/SectionTechnology"
import { SectionOperationsSolution } from "../../components/PageGenerators/SectionOperationsSolution/SectionOperationsSolution"
import { SectionPlanning } from "../../components/PageGenerators/SectionPlanning/SectionPlanning"
import { SectionCaroucelBrands } from "../../components/PageGenerators/SectionCaroucelBrands/SectionCaroucelBrands"
import { SectionComunity } from "../../components/PageGenerators/SectionComunity/SectionComunity"
import { SectionMulticarrier } from "../../components/PageGenerators/SectionMulticarrier/SectionMulticarrier"
import { SectionIndustries } from "../../components/PageGenerators/SectionIndustries/SectionIndustries"
import { SectionStayInformed } from "../../components/PageGenerators/SectionStayInformed/SectionStayInformed"
import FollowUs from "../../components/FollowUs/FollowUs"
import SEO from "../../components/seo"
import post from '../../assets/images/poster.jpg';
import { ModalNewLead } from "../../components/Modals/ModalNewLead/ModalNewLead"
import { navigatePage } from "../../@common/utils/navigate"

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 2,
        rows: 2,
      },
    },
  ],
}

const Generators = ({
  data: { contentfulPaginaGeneradores: contentfulPageGenerators },
}) => {
  if (!contentfulPageGenerators) return null

  const { t } = useTranslation()

  const { languageISO639 } = useSelector(state => state.language)

  const [modalContactGenerator, setModalContactGenerator] = useState(false)

  const {
    seo,
    banner_image,
    banner_multimedia,
    banner_title,
    banner_description,
    success_stories,
    section_3,
    title_section_4,
    item_section_4,
    section_5,
    items_section_5,
    section_6,
    section_7,
    section_8,
    allies,
    section_9,
    section_9_items,
    section_10,
    section_11,
  } = contentfulPageGenerators

  return (
    <Layout>
      {seo && <SEO {...seo} lang={languageISO639} />}

      <ModalNewLead
        handlerClose={(newState) => setModalContactGenerator(newState)}
        visible={modalContactGenerator}
        keyForm={'form_generator'}
      />

      <div className="Generators">
        <Banner
          post={post}
          image={banner_image}
          multimedia={banner_multimedia}
          title={banner_title}
          subtitle={banner_description}
          button1={
            <Button className="btn btn--primary" onClick={() => setModalContactGenerator(true)}>
              {t("button.contact_us")}
            </Button>
          }
          button2={
            <Button className="btn btn--demo" onClick={() => navigatePage("/home-demo")}>
              <img className="btn__img" src={play} alt="img-play" />
              {t("button.see_demo")}
            </Button>
          }
        />
        <section className="var-brands">
          <div className="var-brands__content">
            <Slider {...settings}>
              {success_stories?.map((storie, index) => (
                <div className="brands" key={index}>
                  <img
                    src={storie.logo_gray || storie.logo}
                    alt="brands-transport"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </section>
        <SectionTechnology items={section_3} />
        <SectionOperationsSolution
          title={title_section_4.title}
          description={title_section_4.description?.description}
          items={item_section_4}
        />
        <SectionPlanning
          title={section_5.title}
          comment={undefined/*section_5.comment*/}
          items={items_section_5}
        />
        <SectionCaroucelBrands items={section_6} />
        <SectionComunity
          title={section_7.title}
          description={section_7.description?.description}
          image={section_7.image}
          link={section_7.link}
          comment={section_7.comment}
        />
        <SectionMulticarrier
          items={section_8}
          handlerContact={() => setModalContactGenerator(true)}
        />
        <div className="sectionAllies">
          <div className="sectionAllies__content">
            <div className="sectionAllies__content--left">
              <h1>{t("generators.we_allies")}</h1>
            </div>
            <div className="sectionAllies__content--rigth">
              {allies?.map((allie, i) => (
                <div key={i} className="card">
                  <img className="image" src={allie.photo} alt="Brand" />
                </div>
              ))}
            </div>
          </div>
        </div>
        <SectionIndustries
          title={section_9.title}
          description={section_9.description?.description}
          link={section_9.link}
          items={section_9_items}
          handlerContact={() => setModalContactGenerator(true)}
        />
        <SectionStayInformed
          technologieScope={section_10}
          stayInformed={section_11}
        />
        <div className="page-lifter__followus">
          <FollowUs />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PageGenerators($locale: String) {
    contentfulPaginaGeneradores(language: { language: { eq: $locale } }) {
      banner_multimedia {
        src
      }
      banner_image
      banner_title
      banner_description
      success_stories {
        name
        logo
        logo_secondary
        logo_gray
      }
      section_3 {
        ...SectionFragment
      }
      item_section_4 {
        ...SectionFragment
      }
      title_section_4 {
        ...SectionFragment
      }
      section_5 {
        ...SectionFragment
      }
      items_section_5 {
        ...SectionFragment
      }
      section_6 {
        ...SectionFragment
      }
      section_7 {
        ...SectionFragment
      }
      section_8 {
        ...SectionFragment
      }
      section_9 {
        ...SectionFragment
      }
      section_9_items {
        ...LinkFragment
      }
      allies {
        photo
        label
        link {
          link
        }
      }
      section_10 {
        ...SectionFragment
      }
      section_11 {
        ...SectionFragment
      }
      seo {
        ...SEOFragment
      }
    }
  }
`

Generators.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
}

export default Generators
