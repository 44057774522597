import React, { useEffect, useState } from "react"
import { Button, Form, Input, Checkbox } from "antd"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux";

import { Multimedia } from "../../Multimedia/Multimedia";
import { getOS } from "../../../@common/utils/getOS";
import { useContentfulGeneral } from "../../../contenful/querys/General";
import { HUBSPOT } from "../../../@common/constansts/husbpotForms";
import { hubspot as HubspotActions } from '../../../services/Hubspot/HubspotActions'
import { modals as ModalsActions } from '../../../services/Modals/ModalsActions'
import { navigatePage } from "../../../@common/utils/navigate";

export const SectionStayInformed = ({ technologieScope, stayInformed }) => {

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { languageISO639 } = useSelector(state => state.language)
  const contentfulGeneral = useContentfulGeneral(languageISO639)
  const [os, setOS] = useState()

  useEffect(() => {
    if(!os)
      setOS(getOS())
  }, [])

  const handleSubmit = values => {
    dispatch(HubspotActions.sendForm(
      HUBSPOT.FORM_SUSCRIBE.id,
      HUBSPOT.FORM_SUSCRIBE.keys,
      values
    ))
  }

  const handlerContact = () => {
    navigatePage('/contact-us')
  }

  const openPrivacyPolicy = () => {
    dispatch(ModalsActions.setModals('modalPolicyPrivacity', true))
  }

  return (
    <div className={`SectionStayInformed SectionStayInformed--background-${os}`}>
      <div className="SectionStayInformed--container">
        <div className="box--up" data-aos="fade-up">
          <div className="box--up__left">
            <Multimedia
              className="video-pc"
              src={technologieScope.image}
              interval={4000}
              hover
            />
          </div>
          <div className="box--up__rigth">
            <div className="box--up__rigth--name">
              <h1 className="title">{technologieScope.title}</h1>
              <p className="text">
                {technologieScope.description?.description}
              </p>
            </div>
            <div className="cont-btn">
              <Button className="btn btn--primary" onClick={handlerContact}>
                {t("button.contact_to_agent")}
              </Button>
            </div>
          </div>
        </div>
        <div className="box--down" data-aos="fade-up">
          <div className="box--down__card">
            <div className="box--down__card--left">
              <h1 className="title">{stayInformed.title}</h1>
              <p className="text">{stayInformed.description?.description}</p>
            </div>
            <div className="box--down__card--rigth">
              <p className="email">{t("general.email")}</p>
              <Form 
                name="form_subscribe" 
                onFinish={handleSubmit}
              >
                <Form.Item
                  name="email"
                  rules={[{ required: true, message: t("form.error.required") }]}
                >
                  <Input placeholder={t('form.placeholder.email')}/>
                </Form.Item>
                <Form.Item
                  name="agreement"
                  valuePropName="checked"
                  className="form-lifters__labelCheck"
                  rules={[
                    () => ({
                      validator(rule, value) {
                        if (value) return Promise.resolve()
                        return Promise.reject(t("form.error.required"))
                      },
                    }),
                  ]}
                >
                  <Checkbox>
                    <p className="form-lifters__label form-lifters__labelCheck__check">
                      {t('general.confirm_accept')}{' '}
                      <a target="_blank" onClick={openPrivacyPolicy}>{t('form.privacy_policies')}</a>
                      {/*
                ,{' '}
                <a target="_blank" href={contentfulGeneral.terms_conditions}>{t('form.terms_conditions')}</a>.
                */}
                    </p>
                  </Checkbox>
                </Form.Item>

                <Form.Item className="cont-btn">
                  <Button className="btn btn--primary" htmlType="submit">
                    {t("button.suscription")}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
